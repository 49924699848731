import { parse } from 'date-fns';
import isEmpty from 'lodash.isempty';

export const formatFiltersForServer = (filters) => {
  // get only filters which has values
  // format specific filter types (e.g. date) before send them to the server
  if (!filters?.length) {
    return [];
  }
  const filtersWithValues = filters.filter((item) => {
    return !isEmpty(item.value?.toString()); //!!item.value && item.value?.length
  });

  filtersWithValues.forEach((item) => {
    if (item.type === 'date' || item.type === 'datepicker') {
      item.value = parse(item.value, 'dd/MM/yyyy', new Date());
    }

    if (item.type == 'vendors') {
      item.value = item.value._id;
    }
    if (item.operator) {
      switch (item.operator) {
        case 'ge':
          item.value = { $gte: item.value };
          break;
        case 'le':
          item.value = { $lte: item.value };
          break;
        case 'gt':
          item.value = { $gt: item.value };
          break;
        case 'lt':
          item.value = { $lt: item.value };
          break;
        case 'in':
          item.value = { $in: item.value };
          break;
        case 'ne':
          item.value = { $ne: item.value };
          break;
      }
    }
  });

  return filtersWithValues;
};

export const getFiltersWithValues = (filters) => {
  return filters?.filter((item) => {
    if (Array.isArray(item.value)) {
      return item.value.length > 0;
    } else {
      return !!item.value;
    }
  });
};

export const hasActiveFilters = (filters) => {
  return getFiltersWithValues(filters)?.length > 0;
};
